<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <strong>CarCoster</strong> by
        <a href="https://github.com/mluksic">Miha Lukšič</a>. Contact us at
        <strong>info@carcoster.com</strong>
      </p>
    </div>
  </footer>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
