<template>
  <b-navbar class="is-primary">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/dashboard' }">
        <img src="../assets/undraw_by_my_car_ttge.svg" alt />
      </b-navbar-item>
    </template>
    <template v-if="isUser" slot="start">
      <b-navbar-item
        href="#"
        v-for="(page, index) in userPages"
        :to="page.link"
        tag="router-link"
        :key="index"
        >{{ page.name }}</b-navbar-item
      >
    </template>

    <template v-if="isMechanic" slot="start">
      <b-navbar-item
        href="#"
        v-for="(page, index) in mechanicPages"
        :to="page.link"
        tag="router-link"
        :key="'I' + index"
        >{{ page.name }}</b-navbar-item
      >
    </template>

    <template slot="end">
      <b-navbar-item tag="div">
        <div class="buttons">
          <b-button
            tag="router-link"
            to="/profile"
            class="is-primary"
            icon-left="account"
            >Profile</b-button
          >
          <b-button @click="logout" class="is-light" icon-left="logout-variant"
            >Logout</b-button
          >
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      userPages: [
        { name: 'Cars', link: '/cars' },
        { name: 'Car services', link: '/car-services' },
        { name: 'Fuel expenses', link: '/dashboard' },
        { name: 'Compare car expenses', link: '/car-comparison' }
      ],
      mechanicPages: [{ name: 'Services', link: '/car-comparison' }]
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    isUser() {
      return this.$store.getters.isUser
    },
    isMechanic() {
      return this.$store.getters.isMechanic
    }
  },
  methods: {
    logout() {
      this.$buefy.dialog.confirm({
        title: 'Log out',
        message: 'Are you sure you want to logout?',
        confirmText: 'Yes',
        onConfirm: () => this.$store.dispatch('logoutUser')
      })
    }
  }
}
</script>

<style></style>
